import parttime from '../Images/parttime.svg';
import ngo from '../Images/ngo.svg';
import media from '../Images/media.svg';
import humanities from '../Images/humanities.svg';
import engi from '../Images/engi.svg';
import biz from '../Images/biz.svg';
import design from '../Images/design.svg';
import science from '../Images/science.svg';
import { Link } from 'react-router-dom';
import { display } from '@mui/system';

export default function Internships(props) {

    const categoryImages = {
        'part-time':parttime,
        'ngo': ngo,
        'media':media,
        'humanities': humanities,
        'engineering': engi,
        'business': biz,
        'design': design,
        'science': science,
    };

    return (
        <div style={styles.internshipContainer} className="intern-cat-container">
            <span>
                <div className="row justify-content-evenly">
                    <div className='col-md-12 text-center'>
                        <h2 className="fw-bold fs-1">Internships</h2>
                        <p className="fs-5 fw-lighter mb-1">Choose your niche</p>
                    </div>
                </div>
                <div style={styles.cards} className="intern-cat-cards">

                {
                    Object.keys(categoryImages).map((category, index) => {
                        return (
                            <Link to={`/internships/${category}`} style={{textDecoration: 'none', color: 'black'}} className="cat-card">
                                <InternshipCategoryCard key={index} categoryImg={categoryImages[category]} alt={category} />
                            </Link>
                            )
                        })
                    }
                    </div>
            </span>
        </div>
    )
}

const InternshipCategoryCard = (props) => {
    return (
        <div className='imgCard' style={styles.card}>
            <img src={props.categoryImg} style={styles.cardImg} alt={props.alt} />
            <span style={styles.cardText}>{(props.alt[0].toUpperCase() + props.alt.substring(1)).toString()}</span>
        </div>
    )
}

const styles = {
    internshipContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100vw',
        position: 'absolute',
        bottom: -18,
        // marginTop: '100px',
    },
    cards: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        height: '100%',
        margin: 'auto 40px',
        flexGrow: 10,
        flexWrap: 'wrap'
    },
    card: {
        width: '150px',
        height: '150px',
        borderRadius: '10px',
        margin: '0px 40px',
        padding: 10,
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardImg: {
        width: '60%',
        height: '60%',
        objectFit: 'cover',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    cardText: {
        // textDecoration: 'none !important',
        // textStyle: 'none',
        fontStyle: 'normal',
        // color: 'black',
        fontSize: '1rem',
        fontWeight: '600',
        textAlign: 'center',
    }
}