import React from 'react';
import founderImage from '../Images/rajat.jfif';
import Footer from './Footer';
import rajat2 from '../Images/rajat2.jpeg'
import rajat3 from '../Images/rajatAward.jpeg'
import rajat4 from '../Images/rajat4.jpeg'

const AboutFounder = () => {
  return (
    <div className="about-founder">
      <h2>About the Founder</h2>
      <div className="founder-info">
        <img src={founderImage} alt="Founder" />
        <div>
        <p>
        Rajat Shrivastava is a renowned social activist,
entrepreneur, and media personality. He has
made significant contributions to various fields,
including media, education, and social reform.
        </p>
        <p>
        Mr. Shrivastava is the Director of Delhi
Today, a leading media platform in
India. He has also been appointed as
the National Media Head by the
National Crime Investigation Agency,
which reflects his expertise in the field
of media.
        </p>
        <p>
        Apart from his professional engagements,
Mr. Shrivastava is also the founder of
YUCI, India's first platform to provide
direct mentorship from world leaders in
every field. This platform has received an
appreciation letter from the Hon'ble
Education Minister of India, Sh
Dharmendra Pradhan ji in March 2022.
        </p>
        </div>
      </div>
      <div className="founder-story">
        <h2 style={{textAlign: 'left', marginLeft: '30px'}}>His Story</h2>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{textAlign: 'left'}}>
            <p>Born in a middle class family of Bihar, Rajat Srivastava's life was different from other children, in the house where all the children used to study, Rajat used to do different and most satanic things, for the whole life in the house.<br /><br />
Being a joint family, at the age of 9, he was sent to live at his elder father's house in Bokaro, so that he could concentrate well in studies and progress like the rest of the children, but nature had approved something else, where all the siblings used to get 90% marks and he was a student with 70%, used to come home with complaints from the school.<br />
After passing the matriculation, he was shifted to Ranchi where he was enrolled in Diploma, not even two months had passed that he came in contact with the then Minister of Government of India and Parliament from Ranchi Mr. Subodh Kant Sahay and there began his journey of becoming a youth leader.</p>
        <p style={{textAlign: 'left'}}>
        Mr. Shrivastava's career started in student politics at
the age of 17, and he worked with the student union of
INC. He has also worked with the state and national
committees for 8 years. Later he joined media and
associated with Dainik Jagran and ABP News.
        </p>
        </div>
        <img src={rajat2} style={{width: '700px', height: '450px', borderRadius: '10px', marginRight: '20px'}} alt="Rajat Srivastava Story Image" />
        </div>
      </div>
      <div className="founder-awards">
        <h2>Awards</h2>
        <div className={{display: 'flex'}}>
            <div style={{display: 'flex', padding: '35px'}}>
            <img src={rajat3} style={{width: '500px', marginRight: '20px', borderRadius: 5}} />
            <ul style={{padding: 0}}>
          <li>Mr. Shrivastava's work has been
recognized by various publications,
including Forbes, World's Mirror, NY
Times, ANI, and many more. He has
received several awards and recognitions
for his work, including the Kalam Ratna
Award 2021 by APJ Abdul Kalam
International Foundation, Incredible
Leader of The Year Award by the Ministry
of Small and Medium Enterprises, GOI,
and many more.</li>

          <li>Mr. Shrivastava was also honored with the Young
Entrepreneur of The Year award by NDTV India, the
National Pride Award by the United Federation of
Social Reformers, and the Youngest Entrepreneur
of the Year 2022 by Entrepreneur Forum of India</li>

          <li>Additionally, he has been honored with Indian Nobility Awards by GSIL Education
Society and Neeki Ki Rah Foundation, Yuva Soch Ratna Puruskar by Rotaract Club
Haryana, Global Achiever's Award by EFI, and Certificate of Excellence by Rajneeti
Ki Pathshala.</li>

        </ul>
        </div>
        <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop: '25px', padding: '35px'}}>
            <img src={rajat4} style={{width: '500px', borderRadius: 5}} />
        <ul style={{padding: 0}}>
            <li>
            Mr. Shrivastava has also received Global
Influencers Awards 2022 by Uni Global
Intellectual Foundation, Most Inspiring
Men of India by IIU, Siksha Padam
Samman by All India Principal Association,
and Corona Warrior Award by Coaching
Federation of India.
            </li>

            <li>
            Mr. Shrivastava has also been recognized by various organizations for his social
work, including Memento of Appreciation by GL Bajaj Group and Certificate of
Excellence by Better Hope NGO Gujarat. Overall, Mr. Shrivastava's contributions
to society have been widely appreciated and recognized.
            </li>
            </ul>
            </div>
        </div>
      </div>
      <div className="founder-conclusion">
        <h2 style={{textAlign: 'center'}}>Last But Not Least</h2>
        <p style={{textAlign: 'left'}}>
        In conclusion, Rajat Shrivastava is a true inspiration and a leader in his fields. His
dedication and hard work have helped him achieve many milestones and awards.
He has contributed significantly to media, education, and social reform in India,
making him a notable figure in the country. <br /><br />
From being a student politician to a media personality and entrepreneur, Mr.
Shrivastava has showcased his versatility and leadership skills. His achievements
have been recognized by many organizations, including the Indian government and
international foundations.<br />
Mr. Shrivastava's work reflects his passion for creating positive change in society,
and he continues to inspire many with his efforts. His story serves as a reminder
that with hard work, dedication, and a desire to make a difference, anything is
possible.
        </p><br /><br />
      </div>
      <Footer />
    </div>
  );
};

export default AboutFounder;
