import { Link, useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();
    function PrivacyHandler() {
        navigate('/privacy-policy');
    }
    function ContactHandler() {
        navigate('/contact');
    }
    function TechnologyHandler() {
        navigate('/technologies');
    }
    function CourseHandler() {
        navigate('/courses');
    }
    function AboutHandler() {
        navigate('/about-us');
    }
    return (
        <div className="row justify-content-evenly footer pt-5 pb-2">
            <div className="col-md-2">
                <h5>Courses</h5>
                <p onClick={CourseHandler}>Singing <br></br>Public Speaking <br></br>Entrepreneurship<br></br>Civil Services<br />Journalism</p>
            </div>

            <div className="col-md-2">
                <h5>Mentors</h5>
                <Link to="/about-us#m1" style={{textDecoration: 'none', color: 'white'}}>
                    <p>Sandeep Marwah<br></br>Shatrughan Sinha<br></br>Ramesh Pokhriyal<br></br>Rajeev Shukla<br/>Y. S. Jagan Mohan Reddy<br />Vijender Singh</p>
                </Link>
            </div>

            <div className="col-md-2">
                <h5>Social Media</h5>
                <p className='social-media'>
                    <a href="https://www.linkedin.com/in/rajat-shrivastava-472916187/" target="_blank" rel='noreferrer'>LinkedIn</a>
                    <br></br>
                    <a href="https://www.linkedin.com/in/rajat-shrivastava-472916187/" target="_blank" rel='noreferrer'>Facebook</a>
                    <br></br>
                    <a href="https://www.linkedin.com/in/rajat-shrivastava-472916187/" target="_blank" rel='noreferrer'>Instagram</a>
                    <br></br>
                    <a href="https://wa.me/918851972135" target="_blank" rel='noreferrer'>Whatsapp</a>
                </p>
            </div>

            <div className="col-md-2">
                <h5>Legal</h5>
                <p><span onClick={PrivacyHandler}>Privacy Policy<br></br>Terms & Conditions<br></br></span><span onClick={AboutHandler}>About Us</span><br></br><span onClick={ContactHandler}>Contact YUCI</span><br /><span onClick={()=>{window.location.href="https://externs.tech"}}>Contact Developers</span></p>
            </div>

            <hr></hr>
            <div className="text-justify" style={{ paddingTop: 10, paddingBottom: 10, display: 'flex', justifyContent: 'space-between', paddingLeft: 50, paddingRight: 50 }}>
                <span style={{ fontWeight: '300' }}>Copyright &#169; YUCI 2023.All rights reserved.</span>
                <a href="https://externs.tech/" style={{ textDecoration: 'none', color: 'white', alignSelf: 'flex-end', fontWeight: '400' }}><span style={{ fontWeight: '300' }}>Developed by</span> Externs Software Technologies</a>
            </div>
        </div>
    )
}

export default Footer;