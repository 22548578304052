import Hero from "./Hero";

function Home()
{
    return(
        <div>
            <Hero/>
        </div>
    )
}

export default Home;