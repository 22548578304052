import Footer from "./Footer";
import SiddeshImg from '../Images/sandeep.jpeg'
import FaizanImg from '../Images/shatrughan.jpeg'
import NishanImg from '../Images/ramesh.jpeg'
import UtkarshImg from '../Images/rajeev.jpeg'
import VishalImg from '../Images/jagan.jpeg'
import UtsavImg from '../Images/vijender.jpeg'
import Mission_img from '../Images/mission_images.jpg'
import Vision_img from '../Images/vision_img.jpg'
import Vikrant_img from '../Images/vikrant_img.jpeg'


function About(){

    return(
        <div className="container-fluid about-page">
            <div className="row about-bg">
                <div className="col-md-12">
                    <h1 style={{color:"white",fontWeight:"bolder",textShadow:"3px 3px 3px #4ac6d6", paddingLeft: 40, paddingTop: 80, paddingBottom: 80, paddingRight: 20, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', fontSize:"40px"}} className="">Empowering Hustlers to Innovate</h1>
                </div>
            </div>
            <div className="row justify-content-center pt-4 my-5 p-2 about-desc">
                <div className="col-md-12">
                    <p><span style={{fontSize:"25px",color:"#4ac6d6",fontWeight:"bolder"}}>India </span>has the world's largest youth population, with 70% of the population under 35 years of age. Although the country is making progress in various fields, there are still millions of youths who lack resources to achieve their potential. While the education system primarily prepares students for employment, Indian youth have excelled in fields such as sports, entrepreneurship, science, technology, art, and culture. YUCI aims to empower one million Indian youths by providing a platform for mentorship directly from top professionals in various fields, including entrepreneurship, art, music, politics, education, and athletics. This initiative seeks to help any child, no matter where they are from in the country, achieve their dreams.</p>
                </div>
            </div>
            <div className="row justify-content-evenly align-items-center my-5">
                <div className="col-md-5">
                    <img src={Mission_img} className="img-fluid" style={{borderTopLeftRadius:"80px",borderBottomRightRadius:"80px"}}></img>
                </div>
                <div className="col-md-5 pt-3">
                    <h3 className="text-center" style={{color:"#0d6efd",fontWeight:"bolder"}}>Our Mission</h3>
                    <p className="text-center">YUCI's mission is to provide a platform for mentorship and training in various fields, including entrepreneurship, art, music, politics, education, and athletics, to help Indian youth achieve their dreams. YUCI recognizes that India has a large youth population, and aims to connect young people with mentors and professionals in their desired field to bridge the gap between potential and resources. The initiative seeks to help any child in India, regardless of their background or location, reach their full potential by providing access to top professionals in their desired field. YUCI's ultimate goal is to contribute to India's growth and development by empowering the next generation of leaders and innovators in various fields.</p>
                </div>
            </div>

            <div className="row justify-content-evenly align-items-center my-5 flex-wrap-reverse">
                <div className="col-md-5 pt-3">
                <h3 className="text-center" style={{color:"#0d6efd",fontWeight:"bolder"}}>Our Vision</h3>
                    <p className="text-center">Our vision at YUCI is to create a world where learning is accessible, engaging, and effective for all. We believe that technology can be a powerful tool for transforming education, and we are committed to leveraging it to its fullest potential.Ultimately, our vision is to empower learners of all ages and backgrounds to achieve their full potential, to unlock their creativity and curiosity, and to become lifelong learners and leaders in their communities and beyond.</p>
                </div>
                <div className="col-md-5">
                    <img src={Vision_img} className="img-fluid" style={{borderTopLeftRadius:"80px",borderBottomRightRadius:"80px"}}></img>
                </div>
            </div>

            <div className="row text-center mb-2 mt-4">
                <div className="col-md-12">
                    <h1 className="py-5"><span style={{borderBottom:"5px solid #0d6efd"}}>Our Mentors</span></h1>
                    {/* <p>Meet with out talented team of experts</p> */}
                    {/* <h5>"Teamwork divide the task and multiplies the Success - <span style={{color:"#0d6efd"}}>Steve Jobs"</span></h5> */}
                </div>
            </div>

            <div className="row justify-content-evenly my-3" id="m1">
                <div className="col-md-5 py-5 rounded">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                        <img src={SiddeshImg} className="img-fluid" style={{borderRadius:"50%", width: 200, height: 200, objectFit: 'cover', objectPosition: 'center'}}></img>
                        </div>
                        <div className="col-md-7 py-2">
                            <h3>Sandeep Marwah</h3>
                            <p style={{marginBottom:"8px"}}>Founder of AAFT And Noida Film City</p>
                            <p>He has contributed significantly to the growth and development of the film and television industry in India, and has also been involved in several social and cultural initiatives.</p>
                            <a href="" target="_blank"><button className="btn btn-outline-primary">View LinkedIn</button></a>
                        </div>
                    </div>
                </div>

                <div className="col-md-5 py-5 rounded">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                        <img src={FaizanImg} className="img-fluid" style={{borderRadius:"50%", width: 200, height: 200, objectFit: 'cover', objectPosition: 'center'}}></img>
                        </div>
                        <div className="col-md-7 py-2">
                            <h3>Shatrughan Sinha</h3>
                            <p style={{marginBottom:"8px"}}>Member of Parliament Rajya Sabha & Former Actor</p>
                            <p>He has appeared in over 200 Hindi films and has been a member of the Indian parliament from the Bharatiya Janata Party (BJP) and later the Indian National Congress.</p>
                            <a href="" target="_blank"><button className="btn btn-outline-primary">View LinkedIn</button></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-evenly my-3">
            <div className="col-md-5 py-5 rounded">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                        <img src={NishanImg} className="img-fluid" style={{borderRadius:"50%", width: 200, height: 200, objectFit: 'cover', objectPosition: 'center'}}></img>
                        </div>
                        <div className="col-md-7 py-2">
                            <h3>Ramesh Pokhriyal</h3>
                            <p style={{marginBottom:"8px"}}>Minister Of HRD, Former CM Of Uttrakhand & a Poet</p>
                            <p>He is a member of the Bharatiya Janata Party (BJP) and has served in various roles in the government, including Minister of Human Resource Development.</p>
                            <a href="" target="_blank"><button className="btn btn-outline-primary">View LinkedIn</button></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 py-5 rounded">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                        <img src={UtkarshImg} className="img-fluid" style={{borderRadius:"50%", width: 200, height: 200, objectFit: 'cover', objectPosition: 'center'}}></img>
                        </div>
                        <div className="col-md-7 py-2">
                            <h3>Rajeev Shukla</h3>
                            <p style={{marginBottom:"8px"}}>Politician, Journalist, Former Chairman of IPL, VP of BCCI</p>
                            <p>He is a former chairman of the Indian Premier League (IPL) and has also served as a member of the Indian parliament.</p>
                            <a href="" target="_blank"><button className="btn btn-outline-primary">View LinkedIn</button></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row justify-content-evenly my-3">
            <div className="col-md-5 py-5 rounded">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                        <img src={VishalImg} className="img-fluid" style={{borderRadius:"50%", width: 200, height: 200, objectFit: 'cover', objectPosition: 'center'}}></img>
                        </div>
                        <div className="col-md-7 py-2">
                            <h3>Y. S. Jagan Mohan Reddy</h3>
                            <p style={{marginBottom:"8px"}}>CM of Andhra Pradesh</p>
                            <p>YS Jagan Mohan Reddy is an Indian politician and the current Chief Minister of the Indian state of Andhra Pradesh. He is the founder of the YSR Congress Party and has been active in politics since 2009.</p>
                            <a href="" target="_blank"><button className="btn btn-outline-primary">View LinkedIn</button></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 py-5 rounded">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                        <img src={UtsavImg} className="img-fluid" style={{borderRadius:"50%", width: 200, height: 200, objectFit: 'cover', objectPosition: 'center'}}></img>
                        </div>
                        <div className="col-md-7 py-2">
                            <h3>Vijender Singh</h3>
                            <p style={{marginBottom:"8px"}}>Padma Shree Recipient, Olypmpic Boxer</p>
                            <p>He is a recipient of the Arjuna Award and has won several medals in international competitions, including the bronze medal at the 2008 Beijing Olympics.</p>
                            <a href="https://www.linkedin.com/in/mohd-faizan-khawar-518135121" target="_blank"><button className="btn btn-outline-primary">View LinkedIn</button></a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default About;
