import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// const categories = {}/

const internshipsData = [
    {
      id: 1,
      title: 'Web Development Intern',
      description: 'Help develop and maintain web applications',
      type: 'Paid',
      duration: '3 months',
      employer: {
        logo: 'https://companieslogo.com/img/orig/AMZN-e9f942e4.png?t=1632523695',
        name: 'Example Inc',
      },
    },
    {
      id: 2,
      title: 'Marketing Intern',
      description: 'Assist in developing and executing marketing campaigns',
      type: 'Unpaid',
      duration: '6 months',
      employer: {
        logo: 'https://companieslogo.com/img/orig/AMZN-e9f942e4.png?t=1632523695',
        name: 'Example Inc',
      },
    },
    {
      id: 3,
      title: 'Android Dev Intern',
      description: 'Assist in developing and executing marketing campaigns',
      type: 'Unpaid',
      duration: '6 months',
      employer: {
        logo: 'https://companieslogo.com/img/orig/AMZN-e9f942e4.png?t=1632523695',
        name: 'Example Inc',
      },
    },
    {
      id: 4,
      title: 'Machine Learning Intern',
      description: 'Assist in developing and executing marketing campaigns',
      type: 'Paid',
      duration: '12 months',
      employer: {
        logo: 'https://companieslogo.com/img/orig/AMZN-e9f942e4.png?t=1632523695',
        name: 'Example Inc',
      },
    },
    // Add more internship data here
  ];

export default function InternshipsList(props) {

  const params = useParams();

    const [internships, setInternships] = useState(internshipsData);
  const [filters, setFilters] = useState({
    type: 'All',
    duration: 'All',
  });
  const [sortOption, setSortOption] = useState('default');

  async function getData(){
    const response = await fetch("https://yuci-delhi-default-rtdb.asia-southeast1.firebasedatabase.app/internships.json")
    const data = await response.json()
    console.log("Intern data=>", data)
    console.log(typeof([1, 4][0])==='undefined')
    if (typeof(data[0])!='undefined'){
      setInternships(data[params.type])
    } else {
      console.log(data[params.type]);
      let listNew = []
      Object.keys(data[params.type]).map((i, index)=>{
        listNew.push(data[params.type][i])
      })
      console.log(listNew);
      setInternships(listNew)
    }
  }
  useEffect(()=>{
    getData()
  }, [])

  const filterInternships = () => {
    let filteredInternships = internshipsData;
    if (filters.type !== 'All') {
      filteredInternships = filteredInternships.filter(internship => internship.type === filters.type);
    }
    if (filters.duration !== 'All') {
      filteredInternships = filteredInternships.filter(internship => internship.duration === filters.duration);
    }
    setInternships(filteredInternships);
  };

  const sortInternships = (option) => {
    let sortedInternships = [...internships];
    switch (option) {
      case 'title-asc':
        sortedInternships = sortedInternships.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'title-desc':
        sortedInternships = sortedInternships.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'duration-asc':
        sortedInternships = sortedInternships.sort((a, b) => a.duration.localeCompare(b.duration));
        break;
      case 'duration-desc':
        sortedInternships = sortedInternships.sort((a, b) => b.duration.localeCompare(a.duration));
        break;
      // Add more sorting options here
      default:
        break;
    }
    setInternships(sortedInternships);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSortChange = (event) => {
    const { value } = event.target;
    setSortOption(value);
    sortInternships(value);
  };

  return (
    <div className="container" style={{display: 'flex'}}>
      <div className="filters" style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // width: '200px',
        padding: 40,
        // maxHeight: '70vh',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: '0 0 4px -1px #000',
      }}>
        <h2 style={{fontWeight: '600', fontSize: 24, marginBottom: 20}}>Filters</h2>
        <label>Type:</label>
        <select name="type" value={filters.type} onChange={handleFilterChange}>
          <option value="All">All</option>
          <option value="Paid">Paid</option>
          <option value="Unpaid">Unpaid</option>
        </select>
        <br />
        <label>Duration:</label>
        <select name="duration" value={filters.duration} onChange={handleFilterChange}>
          <option value="All">All</option>
          <option value="3 months">3 months</option>
          <option value="6 months">6 months</option>
          <option value="12 months">12 months</option>
        </select>
        <br />
        <button onClick={filterInternships}>Apply Filters</button>
        <br />
        <label>Sort By:</label>
        <select value={sortOption} onChange={handleSortChange}>
          <option value="default">Default</option>
          <option value="title-asc">Title (A-Z)</option>
          <option value="title-desc">Title (Z-A)</option>
          <option value="duration-asc">Duration (Shortest-Longest)</option>
          <option value="duration-desc">Duration (Longest-Shortest)</option>
        </select>
      </div>
      <div className="internship-list" style={{
        flexGrow: 4,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        marginLeft: 40,
      }}>
        <h2 style={{
          fontWeight: '600',
          fontSize: 30,
          marginTop: 5,
          marginBottom: 20,
        }}>Latest Internships</h2>
        <div className="cards" style={{
          textAlign: 'left',
        }}>
          {internships.map((internship, index) => (internship &&
            <div className="card" key={index} style={{
              boxShadow: '0 0 4px -1px #000',
            }}>
              <div className="inline">
                <img src={internship.employer.logo} alt={internship.employer.name} />
                <h3>{internship.title}</h3>
              </div>
              <p>{internship.description}</p>
              <p>Type: {internship.type}</p>
              <p>Duration: {internship.duration}</p>
              <p>Employer: {internship.employer.name}</p>
              <button onClick={()=>{alert("Applications to be started after Production!")}}>Apply Now</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}