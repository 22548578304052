import React from 'react';
import PodImage from '../Images/rajpod.png'
import '../App.css'

const Podcast = () => {
  return (
    <div style={styles.container} className="podcast">
      <div style={styles.content} className="content-container-pod">
        <h1 style={styles.heading}>The Unfiltered Life story with Rajat Shrivastava</h1>
        <p style={styles.description}>We are thrilled to announce our new podcast series, where we will be interviewing successful individuals from all walks of life and sharing their inspiring stories with the world.</p>
        <p style={styles.description}>As you may know, Rajat Shrivastava is a renowned social activist, entrepreneur, and media personality. His achievements include receiving the Kalam Ratna Award 2021 by APJ Abdul Kalam International Foundation, the Incredible Leader of The Year Award by the Ministry of Small and Medium Enterprises, and the Changemaker of The Year Award by Hero Moto Corp, among others.</p>
        <p style={styles.description}>Our goal with this podcast is to provide a platform for guests to share their stories of struggle, perseverance, and triumph, and to ignite the young minds by showcasing the power of resilience and determination.</p>
        <p style={styles.description}>If you are a passionate, driven individual with a story to share, we invite you to register by filling out the form. Whether you're an entrepreneur, artist, athlete, or activist, we want to hear from you.</p>
        <p style={styles.description}>We are confident that "The Unfiltered Life story with Rajat Shrivastava" will become a dominant force in the world of podcasts, and we look forward to sharing your inspiring stories with our listeners.</p>
        <button style={styles.button} className="podcast-btn">Register Now</button>
      </div>
      <div style={styles.imageContainer} className="img-container-pod">
        <img style={styles.image} src={PodImage} alt="Podcast"/>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    // height: '100vh',
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: '0 20px',
  },
  heading: {
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 30,
    color: '#2d2d2d',
    marginLeft: 10
  },
  description: {
    fontSize: 18,
    lineHeight: 1.5,
    marginBottom: 20,
    marginLeft: 15,
    color: '#2d2d2d',
  },
  button: {
    padding: '10px 30px',
    borderRadius: 10,
    border: '3px rgb(42, 98, 157) solid',
    backgroundColor: 'rgb(42, 98, 157)',
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: 15,
    marginLeft: 15,
    marginBottom: 40,
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    marginLeft: 10,
    marginTop: -40
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 10,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.3)',
  },
};

export default Podcast;
