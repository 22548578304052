import './App.css';
import { Route, Routes, Link } from 'react-router-dom';
import Home from './Components/Home';
import Courses from './Components/Courses';
import Technologies from './Components/Technologies';
import Contact from './Components/Contact';
import CourseDetail from './Components/CourseDetail';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ScrollToTop from './Components/ScrollToTop';
import Display from './Components/Display';
import CoursePage from './Components/CoursePage';
import Loading from './Images/loading.gif'
import { useEffect, useState } from 'react';
import Logo from './Images/yuciLogo.png';
import About from './Components/About';
import AboutFounder from './Components/AboutFounder';
import Register from './Components/Register';
import {Sling as Hamburger} from 'hamburger-react'
import {stack as MenuWrapper} from 'react-burger-menu'
import MuiHeaderr from './Components/Header';
import Internships from './Components/Internships';
import InternshipsList from './Components/InternshipsList';
import InternshipDetail from './Components/InternshipDetail';
import { bgcolor } from '@mui/system';
import Podcast from './Components/Podcast';
// So in future if we need to add any new card we can just pass JSON Card data in Courses.js File

function App() {
  const [loading, setloading] = useState(false)
  const [itemClicked, setitemClicked] = useState(false)
  const [shouldAddClass, setShouldAddClass] = useState(false);
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {

    // setloading(true)
    // onSiteLoaded()

    // set a timeout to add the class after 10 seconds
    const timeoutId = setTimeout(() => {
      setShouldAddClass(true);
    }, 20000)
    return () => clearTimeout(timeoutId);
  }, []);
  return (

    <div className="App" id="App" onLoad={() => { document.getElementById('loader-container').style.display = 'none' }}>
      <MenuWrapper
        pageWrapId='App'
        styles={menuStyles}
        menuClassName="bgMenu"
        itemClassName='bgItem'
        itemListClassName='bgList'
        >
        <Link to="/" className="nav-link active text-white link" aria-current="page" >Home</Link>
        <Link to="/courses" className="nav-link active text-white link" aria-current="page" >Courses</Link>
        <Link to="/internship-categories" className="nav-link active text-white link" aria-current="page" >Internships</Link>
        <Link to="/about-us" className="nav-link active text-white link" aria-current="page" >About Us</Link>
        <Link to="/contact" className="nav-link active text-white link" aria-current="page" >Contact</Link>
        <Link to="/podcast" className="nav-link active text-white link" aria-current="page" >Unfiltered Story</Link>
        <Link to="/about-founder" className="nav-link active text-white link" aria-current="page" >About Founder</Link>
      </MenuWrapper>
      {
        // <div id="loader-container" style={{height: '100vh', width: '100vw', background: 'black', color: 'black', display: 'none', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'fixed', zIndex: 1000}}>
        //   <img src={Loading} alt='Loading Image' />
        //   {/* Loading... */}
        // </div>
      }
      {/* navbar */}
      <p className={shouldAddClass ? 'd-none' : 'early-bird-text'} style={{marginBottom: 0}}><i class="bi bi-gift-fill"></i> End of Season Sale - Atleast 80% off on all courses [Limited Seats Only]</p>
      {/* <p className='early-bird-text'><i class="bi bi-twitter"></i> Early bird offer : Guaranteed free Internship</p> */}

    
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <img src={Logo} className="logo me-16" />
          {/* <Link to="/" className="navbar-brand text-white link">YUCI Edu</Link> */}
          {/* <button className="navbar-toggler" type="button" id="trial-btn" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-white">
              <button className="call-btn ms-2"><a href="tel:8851972135" className="call-anchor">Join Us</a></button>
            </ul>
          </div>
        </div>
      </nav>
      {/* <MuiHeaderr></MuiHeaderr> */}
      {/* <Hamburger toggled={isOpen} toggle={setOpen} direction="right" color="#4FD1C5" rounded /> */}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/courses" element={<CoursePage />}></Route>
        {/* Temporary route below */}
        <Route path="/course-page" element={<CoursePage />}></Route>
        <Route path="/technologies" element={<Technologies />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/course-detail" element={<CourseDetail />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/view-student" element={<Display />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/internship-categories" element={<Internships />}></Route>
        <Route path="/internships/:type" element={<InternshipsList />}></Route>
        <Route path="/apply-for-internship" element={<InternshipDetail />}></Route>
        <Route path="/podcast" element={<Podcast />} />
        <Route path="/about-founder" element={<AboutFounder />} />
      </Routes>
    </div>
  );
}

const menuStyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    left: '40px',
    top: '47px'
  },
  bmBurgerBars: {
    // background: '#373a47',
    background: 'rgb(69, 165, 255)',
    borderRadius: 8,
    background: "black",
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#444444',
    marginTop: 10,
    marginRight: 30,
    fontWeight: '600'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#373a47',
    background: 'white',
    // backgroundColor: "rgb(67, 177, 255)",
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    timeDuration: '0.3s',
    // color: 'white'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    display: 'flex',
    flexDirection: 'column',
    color: '#b8b7ad ',
    // color: 'white',
    padding: '0.8em'
  },
  bmItem: {
    marginTop: 10,
    fontWeight: '500',
    display: 'inline-block',
    width: '100%',
    padding: '1rem 1.7rem',
    borderRadius: 12,
    background: "rgb(67, 177, 255)",
    color: "white",
    background: 'white',
    color: 'rgb(67, 177, 255)'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

export default App;
